.kyc-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10rem;
}

.profile {
  height: 100%;

  &--modal {
    background-color: $background !important;

    .modal-form {
      padding: 0.5rem;

      .input-field {
        margin-top: 0.5rem;

        &:last-of-type {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &--header {
    display: flex;
    margin-bottom: 2rem !important;
    margin-top: 2rem;

    div {
      display: flex;
      align-items: center;

      h5 {
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        color: $secondary;

        @media screen and (min-width: $screen-lg) {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }

      h5.status {
        display: flex;
        align-items: center;

        padding-left: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        color: $main;

        button {
          margin-left: 0.5rem;
          margin-bottom: 10px;
        }
      }
    }
  }

  &--form {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $screen-lg) {
      flex-direction: row;
      width: 100%;
    }

    .form-container {
      @media screen and (min-width: $screen-lg) {
        width: 50%;
      }
    }

    .form-row {
      div {
        margin-right: 1rem;
        margin-bottom: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }

      @media screen and (min-width: $screen-md) {
        display: flex;
        flex-wrap: nowrap;
      }
    }

    .form-label {
      font-size: .8rem !important;
      color: $secondary;
      font-weight: normal;
    }

    h5 {
      position: relative;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.25rem;
      text-transform: uppercase;
      color: $secondary;
      margin-bottom: 1rem;

      @media screen and (min-width: $screen-lg) {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          width: 45%;
          height: 0.0625rem;
          background: #d8e6f0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    button {
      max-width: 19.125rem;
      width: 100%;
      color: white;
    }

    img {
      display: none;

      @media screen and (min-width: $screen-lg) {
        display: block;
        margin-left: auto;
      }
    }
  }
}

@import '~react-phone-input-2/lib/plain.css';

.react-tel-input {
  width: 100%;

  .flag-dropdown {
    border: none;

    .arrow {
      margin-left: 5px !important;
    }
  }

  .selected-flag {
    padding-left: 16px !important;
    background: rgba(255, 255, 255, 0.3) !important;
  }

  input {
    background: rgba(255, 255, 255, 0.3) !important;
    border: 1px solid $white !important;
    box-sizing: border-box;
    border-radius: 4px;
    height: 56px !important;
    color: $dark !important;
    padding: 16px;
    padding-left: 62px !important;
    font-size: 16px !important;
    width: 100% !important;

    &::placeholder {
      font-size: 16px;
      color: $grey;
    }

    &:focus {
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid rgba(33, 120, 251, 0.25);
      box-sizing: border-box;
    }
  }
}
