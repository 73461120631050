.gradient-background {
  background: radial-gradient(27.27% 162.47% at 27.27% 21.95%, #2072ec 0%, #2178fb 100%);

  .header {
    background-color: transparent !important;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  a,
  b {
    color: $white;
  }

  p.light {
    color: $grey-for-blue;
  }

  .icon-rounded {
    background: linear-gradient(90deg, #166ced 0%, #1e74f7 100%);
    box-shadow: 0px 16px 24px #186ff2, inset 2px 4px 8px #3384ff;
  }
}

.solid-background {
  background-color: #2a78f4;

  .header {
    background-color: transparent !important;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  a,
  b {
    color: $white;
  }

  p.light {
    color: $grey-for-blue;
  }
}

.split-background-color {
  background: linear-gradient(to top, $main 50%, white 0%);

  .dark-background {
    background-color: white;
  }
}
.landing-bottom-section {
  .cta-container {
    margin: 6rem 0 0 0;
  }
}
