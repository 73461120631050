.token-landing {
  padding: 0.875rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &--intro {
    display: flex;
    align-items: center;

    @media screen and (min-width: $screen-md) {
      flex: 1;
    }

    img {
      max-width: 60%;
    }
  }

  .landing-sale {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      text-align: center;
    }

    a {
      margin-top: 1rem;
    }
  }

  &--countdown {
    border-top: 0.0625rem solid #d8e6f0;
    padding-top: 1rem;
    margin-top: 0.875rem !important;

    @media screen and (min-width: $screen-md) {
      display: grid;
      grid-template-columns: repeat(2, 6fr);
      column-gap: 4.875rem;
      padding-top: 2rem;
    }

    > div:first-child {
      h2 {
        font-weight: bold;
        text-transform: uppercase;
        color: $dark;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 2rem;
        width: 100%;

        @media screen and (min-width: $screen-md) {
          font-size: 2.5rem;
          line-height: 3rem;
          max-width: 16.5625rem;
        }

        @media screen and (min-width: $screen-lg) {
          max-width: 33.125rem;
        }
      }

      p {
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $secondary;

        @media screen and (min-width: $screen-md) {
          font-size: 1.25rem;
          line-height: 1.75rem;
        }
      }
    }

    > div:last-child {
      p {
        font-weight: bold;
        text-transform: uppercase;
        color: $secondary;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;

        @media screen and (min-width: $screen-md) {
          font-size: 1rem;
          line-height: 1.25rem;
          margin-top: 0;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
