@keyframes claimGlow {
  0% {
    box-shadow: 4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5), inset 1px 2px 2px rgba(191, 217, 255, 0.5);
    height: 256px;
  
  }
  100% {
    height: 315px;
    box-shadow: 4px 24px 24px rgba(53, 123, 230, 0.8), inset -2px -3px 4px rgba(53, 123, 230, 0.5), inset 1px 2px 2px rgba(191, 217, 255, 0.5);
  }
}
@keyframes claimClick {
  0% {box-shadow: 4px 24px 24px rgba(53, 123, 230, 0.8), inset -2px -3px 4px rgba(130, 148, 177, 0.5), inset 1px 2px 2px rgba(191, 217, 255, 0.5);}
  100% {box-shadow: none;}
}

@keyframes buttonAppear {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

//spinner
@keyframes spin-start {
  0% {transform: translate3d(0,0,0);}
  100% {transform: translate3d(0,-900%,0);}
}
@keyframes spin {
  0% {transform: translate3d(0,0,0);}
  100% {transform: translate3d(0,-900%,0);}
}
@keyframes spin-stop {
  0% {transform: translate3d(0,0,0);}
  90% {transform: translate3d(0,-810%,0);}
  100% {transform: translate3d(0,-800%,0);}
}
@keyframes blur-start {
  0% {filter: blur(0);}
  70% {filter: blur(10px);}
  100% {filter: blur(10px);}
}
@keyframes blur-stop {
  0% {filter: blur(10px);}
  70% {filter: blur(0);}
  100% {filter: blur(0);}
}