.legend {
  display: flex;
  align-items: center;
  margin-bottom: 1rem !important;

  &--label-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (min-width: $screen-xl) {
      align-items: center;
      flex-direction: row;
    }
  }

  &--color {
    min-width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;
    margin-right: 1rem !important;
    box-shadow: 0.25rem 1rem 2rem #c3d4ec, inset 0.0625rem 0.125rem 0.125rem rgba(255, 255, 255, 0.5);
  }

  &--label {
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-transform: uppercase;
    flex: 1;
    color: $secondary;

    @media screen and (min-width: $screen-sm) {
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
  }

  &--value {
    font-weight: bold;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: $dark;
    line-height: 1.25rem;

    @media screen and (min-width: $screen-sm) {
      font-size: 1rem;
    }
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}
