.large-wrapper {
  // used in footer and header
  padding: 1.6rem 1rem;

  @media screen and (min-width:$screen-md) {
    padding: 1.6rem 1.6rem;
  }

  @media screen and (min-width:$screen-lg) {
    padding: 1.6rem 1.5rem;
  }
}

.page-wrapper {
  padding: 0 1rem;

  @media screen and (min-width:$screen-md) {
    padding: 0 1.6rem;
  }

  @media screen and (min-width:$screen-lg) {
    padding: 0 1.5rem;
  }
}

.flex {
  display: flex;
  flex: 1;
}

.centerFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.logo,
.logo-white {
  background: url(../assets/images/revuto-logo-new-small.png) no-repeat center;
  background-size: contain;
  width: 127px;
  height: 42px;
}

.logo-white {
  filter: invert(100%);
}

hr {
  border-top: 1px solid #87A5BB;
  opacity: 0.25;
  // width: 100vw;
}

.section-spacing {
  margin-top: 6rem;
}

.hidden {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.plain-pages {
  max-width: 900px;

  h1 {
    font-size: 48px;
    text-align: left;
    margin-bottom: 8px;
  }

  h5 {
    margin: 1rem 0;
  }

  p,
  i,
  a {
    margin-top: 21px;
    font-size: 1.1rem;
    letter-spacing: -0.03px;
    line-height: 1.58;
  }

  a {
    text-decoration: underline;
    text-decoration-color: $dark;
  }

  li {
    margin-bottom: 1rem;
    line-height: 1.6rem;
  }

  tbody {
    tr:first-child {
      text-align: center;
    }

    td {
      border: 1px solid $dark;

      p,
      a {
        font-size: 0.8rem;
      }
    }
  }
}
