.notification {
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 16px;
  padding: 3.2rem;

  @media (min-width: $screen-md) {
    max-width: 50%;
  }
}
