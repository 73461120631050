.partnership-container {
  .iohk {
    background: url(../../assets/images/partners/iohk.png) no-repeat center;
    background-size: contain;
    width: 64px;
    height: 47px;
  }

  .async-labs {
    background: url(../../assets/images/partners/async.png) no-repeat center;
    background-size: contain;
    width: 62px;
    height: 46px;
  }

  .blackdragon {
    background: url(../../assets/images/partners/blackdragon.png) no-repeat center;
    background-size: contain;
    width: 140px;
    height: 70px;
  }

  .electrocoin {
    background: url(../../assets/images/partners/electrocoin.png) no-repeat center;
    background-size: contain;
    width: 126px;
    height: 21px;
  }

  .blokbe {
    background: url(../../assets/images/partners/blokbe.png) no-repeat center;
    background-size: contain;
    width: 123px;
    height: 30px;
  }

  .zunic {
    background: url(../../assets/images/partners/zuniclaw.png) no-repeat center;
    background-size: contain;
    width: 55px;
    height: 62px;
  }

  .beyondi {
    background: url(../../assets/images/partners/beyondi.png) no-repeat center;
    background-size: contain;
    width: 97px;
    height: 64px;
  }

  .chainlink {
    background: url(../../assets/images/partners/chainlogo.svg) no-repeat center;
    background-size: contain;
    width: 144px;
    height: 81px;
  }

  .adalite {
    background: url(../../assets/images/partners/adalite.png) no-repeat center;
    background-size: contain;
    width: 150px;
    height: 50px;
  }

  .gateIo {
    background: url(../../assets/images/partners/gate.io.png) no-repeat center;
    background-size: contain;
    width: 150px;
    height: 50px;
  }

  .kucoin {
    background: url(../../assets/images/partners/kucoin.png) no-repeat center;
    background-size: contain;
    width: 150px;
    height: 50px;
  }

  .marketacross {
    background: url(../../assets/images/partners/marketacross.png) no-repeat center;
    background-size: contain;
    width: 150px;
    height: 50px;
  }

  .sumsub {
    background: url(../../assets/images/partners/Sumsub.png) no-repeat center;
    background-size: contain;
    width: 150px;
    height: 50px;
  }

  .vyfi {
    background: url(../../assets/images/partners/vyfi.png) no-repeat center;
    background-size: contain;
    width: 150px;
    height: 50px;
  }

  .bitmart {
    background: url(../../assets/images/partners/bitmart.svg) no-repeat center;
    background-size: contain;
    width: 132px;
    height: 33px;
  }

  .genius-yield {
    background: url(../../assets/images/partners/genius_yield.png) no-repeat center;
    background-size: contain;
    width: 141px;
    height: 34px;
  }

  .coti {
    background: url(../../assets/images/partners/coti.svg) no-repeat center;
    background-size: contain;
    width: 122px;
    height: 40px;
  }

  .adahandle {
    background: url(../../assets/images/partners/ada_handle_light_o.svg) no-repeat center;
    background-size: contain;
    width: 155px;
    height: 50px;
  }

  .profila {
    background: url(../../assets/images/partners/profila-logo-main_o.svg) no-repeat center;
    background-size: contain;
    width: 85px;
    height: 20px;
  }

  .cardax {
    background: url(../../assets/images/partners/cardax_logo.png) no-repeat center;
    background-size: contain;
    width: 171px;
    height: 40px;
  }

  .cardahub {
    background: url(../../assets/images/partners/Cardahub.png) no-repeat center;
    background-size: contain;
    width: 150px;
    height: 37.5px;
  }

  .railsr {
    background: url(../../assets/images/partners/railsr.png) no-repeat center;
    background-size: contain;
    width: 80px;
    height: 32px;
  }

  .worldpay {
    background: url(../../assets/images/partners/worldpay.png) no-repeat center;
    background-size: contain;
    width: 120px;
    height: 40px;
  }

  .banxa {
    background: url(../../assets/images/partners/Banxa.png) no-repeat center;
    background-size: contain;
    width: 120px;
    height: 20px;
  }

  .realfi {
    background: url(../../assets/images/partners/Cardano_RealFi.png) no-repeat center;
    background-size: contain;
    width: 100px;
    height: 72px;
  }

  .indigo {
    background: url(../../assets/images/partners/Indigo_Protocol.png) no-repeat center;
    background-size: contain;
    width: 80px;
    height: 80px;
  }

  .nmkr {
    background: url(../../assets/images/partners/NMKR.png) no-repeat center;
    background-size: contain;
    width: 137px;
    height: 60px;
  }
}
