.defi-container {
  padding: 3rem 2rem 0;

  @media screen and (max-width:$screen-sm) {
    padding: 3rem 1rem 0;
    img {
      padding-top: 2rem;
    }
    h2 {
      text-align: center;
    }
  }

  img {
    width: 100%;
    max-width: 360px;
  }

  h2 {
    margin: 0;
  }

  .row {
    flex-direction: column-reverse;
  }

  @media screen and (min-width:$screen-md) {
    img {
      margin-top: -14%;     // hack for centering with title
    }

    .row {
      flex-direction: row;
    }
  }
}
