.wallet-page {
  background: radial-gradient(59.9% 59.9% at 50% 87.26%, rgba(138, 184, 255, 0.16) 0%, rgba(138, 184, 255, 0) 40.37%),
    radial-gradient(36.28% 101.93% at 67.94% 50%, #3485ff 0.01%, #1364de 100%);
  padding-bottom: 8rem;
  overflow-x: hidden;

  .header {
    background-color: transparent;
    border-bottom: 1px solid $main;

    header {
      padding: 0.5rem 1.5rem;
    }
  }

  .wallet-hero {
    margin-top: 2rem;
    position: relative;
    display: grid;
    grid-template-columns: minmax(500px, auto) 480px;

    h1 {
      font-family: 'Circe Bold';
      text-transform: uppercase;
      // font-size: clamp(3rem, 6vw, 6rem);
      font-size: 72px;
      font-weight: 800;
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.6) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 4rem;
      margin-bottom: 0;
      line-height: 88px;
    }

    .mobile-links {
      margin: 2rem 0;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    @media (max-width: $screen-xl) {
      padding: 0 1rem;
    }

    @media (max-width: $screen-md) {
      display: block;
      text-align: center;

      .mobile-links {
        margin: 2rem auto;
        justify-content: center;
      }
    }

    @media (max-width: $screen-sm) {
      h1 {
        font-size: 40px;
        line-height: 50px;
      }

      .mobile-links a:first-child img,
      .mobile-links a:nth-child(3) img {
        max-width: 135px;
        max-height: 40px;
      }

      .mobile-links a:nth-child(2) img {
        max-width: 120px;
        max-height: 40px;
      }
    }
  }
}

.wallet-main {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;

  .wallet-shorts {
    width: 100%;
    max-width: 1200px;
    position: relative;
    top: -8rem;
    background: linear-gradient(180deg, #e9f1f9 0%, #f5f8fb 100%);
    box-shadow: inset 1px 2px 2px #ffffff;
    backdrop-filter: blur(40px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 8px 8px 0px 0px;
    padding: 0 2rem;

    display: grid;
    grid-template-columns: repeat(3, minmax(22ch, 1fr));
    // justify-content: center;
    align-items: center;
    gap: 1rem;

    .box-transparent-white {
      position: relative;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 18.75%, #ffffff 65.1%);
      border-radius: 0px 0px 8px 8px;
      z-index: 2;
      padding: 0 2ch;
      height: 160px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        text-transform: uppercase;
        color: $main;
        font-weight: bold;
        max-width: 20ch;
      }

      .box-transparent-white-shadow {
        display: block;
        position: absolute;
        top: 128px;
        width: 160px;
        height: 160px;
        aspect-ratio: 1 / 1;
        background: linear-gradient(
          180deg,
          rgba(137, 184, 255, 0.24) 4.7%,
          rgba(255, 255, 255, 0.0832) 55.12%,
          rgba(255, 255, 255, 0) 98.16%
        );
        border-radius: 50%;
        z-index: 1;
        clip-path: polygon(0px 100%, 100% 100%, 100% 32px, 0% 32px);
      }
    }

    .box-transparent-white::before {
      content: '';
      display: inline-block;
      background: #ffffff;
      box-shadow: 4px 16px 24px rgba(70, 122, 201, 0.15);
      border-radius: 0px 0px 8px 8px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 6px;
      z-index: 3;
    }

    @media (max-width: $screen-md) {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background: transparent;
      border: none;
      box-shadow: none;
      backdrop-filter: none;

      .box-transparent-white {
        height: max-content;
        width: 100%;
        background: linear-gradient(180deg, rgba(240, 240, 250, 100%) 18.75%, #ffffff 65.1%);
        border-radius: 8px;
        padding: 1rem;

        p {
          max-width: initial;
        }

        .box-transparent-white-shadow {
          display: none;
        }
      }

      .box-transparent-white::before {
        border-radius: 8px 8px 0px 0px;
      }
    }

    @media (max-width: $screen-sm) {
      top: -2rem;
    }
  }

  .wallet-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -100px;

    h2 {
      font-family: 'Circe Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 80px;
      line-height: 120px;
      color: #5e9eff;
      text-transform: uppercase;
      margin: 0;
    }

    h3 {
      font-family: 'Circe Bold';
      font-style: normal;
      // font-weight:bolder;
      font-size: 28px;
      line-height: 40px;
      color: $dark;
      filter: opacity(0.9);
      margin: 0;
      max-width: 40ch;
    }
  }

  @media (max-width: $screen-md) {
    position: relative;
    top: -300px;
    background-color: $background;
  }

  @media (max-width: $screen-sm) {
    top: -400px;
    box-shadow: 0px -100px 200px 0px rgba(53, 123, 230, 1);
    clip-path: polygon(100% -333px, 0 -333px, 0 100%, 100% 100%);

    .wallet-title {
      top: 0;

      h2 {
        font-family: 'Circe Bold';
        font-size: 60px;
        line-height: 72px;
      }

      h3 {
        font-size: 24px;
        line-height: 36px;
        margin-top: 2rem;
        margin-bottom: 6rem;
      }
    }
  }
  .wallet-offers-grid {
    display: grid;
    grid-template-columns: repeat(2, 500px);
    grid-template-rows: repeat(2, max-content);
    column-gap: 1rem;
    row-gap: 90px;
    justify-content: center;

    .wallet-offers {
      position: relative;
      background: linear-gradient(180deg, #e9f1f9 0%, rgba(245, 250, 255, 0.75) 100%);
      box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset 1px 2px 2px #ffffff;
      backdrop-filter: blur(40px);
      border-radius: 8px;
      text-align: center;
      padding: 60px 3rem 0 3rem;

      .wallet-offers-icon {
        position: absolute;
        width: 120px;
        height: 120px;
        left: calc(50% - 60px);
        top: -60px;
        border-radius: 50%;

        background: #f6f9fd;
        box-shadow: 4px 16px 12px rgba(103, 145, 208, 0.075), inset 1px 2px 2px #ffffff;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      h3,
      p {
        display: block;
        border-bottom: 1px solid $white;
        padding: 1rem 0;
        margin: 0;
        line-height: 1.25;
      }

      p:last-child {
        border-bottom: none;
      }

      h3 {
        font-family: 'Circe Bold';
        color: $main;
        text-transform: uppercase;
        line-height: 1;
      }
    }

    @media (max-width: $screen-md) {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: $screen-lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .wider {
    width: 96%;
    max-width: 1440px;
  }

  .stake-header {
    font-family: 'Circe Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 56px;
    color: #5e9eff;

    /* or 140% */
    text-align: center;
    text-transform: uppercase;

    max-width: 30ch;
    margin: 100px auto 2rem auto;
  }

  @media (max-width: $screen-sm) {
    .stake-header {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .wallet-stake {
    background: linear-gradient(180deg, #5e9eff 0%, #4d8ff2 100%);

    /* btn */
    box-shadow: 4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5),
      inset 1px 2px 2px rgba(191, 217, 255, 0.5);
    border-radius: 4px;

    display: flex;
    justify-content: space-between;
    padding: 0.5rem 3rem;

    .stake {
      max-width: 45ch;

      h3,
      p {
        color: $white;
      }

      h3 {
        font-family: 'Circe Bold';
      }

      p {
        font-size: 16px;
      }
    }

    .stake-action {
      position: relative;
      top: -60px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 24px 48px;
        color: $main;
        font-weight: bold;
        /* btn white */
        background: linear-gradient(180deg, #e9f1f9 0%, #c8ddfa 100%);

        /* btn white */
        box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1),
          inset 1px 2px 2px #ffffff;
        border-radius: 4px;
      }
    }

    @media (max-width: $screen-lg) {
      flex-direction: column;
      justify-content: center;
      padding: 1.5rem 3rem;

      .stake {
        max-width: initial;
        text-align: center;
      }

      .stake-action {
        top: 0;
      }
      .stake-subs {
        display: none;
      }
    }
  }

  .stake-note {
    color: $dark;
    font-weight: bold;
    text-align: center;
    font-size: 0.6rem;
    filter: opacity(0.5);
  }
}

.blue {
  color: $main;
}

@media (max-width: $screen-md) {
  .pull-up {
    margin-top: -360px;
  }
}

// @media (max-width: $screen-lg) {
//   .pull-up {
//     margin-top: 0;
//   }
// }
