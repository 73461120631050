.nft-page {
  .nft-list {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding-bottom: 2rem;
    justify-content: space-between;

    &:after {
      content: "";
      flex: auto;
    }

    @media screen and (max-width: $screen-md) {
      justify-content: center;
    }
  }

  .claim-message {
    display: inline-flex;
    border: 1px solid rgba(135,165,187,.25);
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 1.5rem;

    p {
      color: $grey-for-white;
      text-transform: uppercase;
    }
  }

  .pagination {
    padding-bottom: 2.5rem;
  }
}
