@import './animations.scss';

.image-spinner {
  background: $blue-gradient;
  box-shadow: 4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5),
    inset 1px 2px 2px rgba(191, 217, 255, 0.5);
  padding: 16px;
  border-radius: 16px;
  user-select: none;
  &.isClaimable {
    box-shadow: 4px 24px 24px rgba(53, 123, 230, 0.8), inset -2px -3px 4px rgba(53, 123, 230, 0.5),
      inset 1px 2px 2px rgba(191, 217, 255, 0.5);
    animation: claimGlow 0.2s linear;
  }
  button{
    animation: buttonAppear 0.3s ease-in-out;
  }
  .image-container {
    width: 300px;
    height: 256px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .loadingNFTs{
      color: $white;
      padding: 4.3rem 0;
    }
    .img-wrapper {
      width: 100%;
      height: 100%;
      flex-shrink: 0;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        image-rendering: -webkit-optimize-contrast;
      }
      &.spin-start {
        animation: spin-start 1s ease-in, blur-start 1s ease-in;
      }
      &.spin {
        animation: spin 0.5s infinite linear;
        filter: blur(10px);
      }
      &.spin-stop {
        animation: spin-stop 1.5s ease-out, blur-stop 1.5s ease-out;
        animation-fill-mode: forwards;
        filter: blur(0);
      }
    }
  }
  button {
    width: 100%;
    padding: 12px;
    margin-top: 16px;
  }
}
