.hero-container {
  @media screen and (min-width: $screen-sm) {
    padding-bottom: 3rem;
  }

  @media screen and (min-width: $screen-xl) {
    padding-bottom: 0;
  }

  .hero-image {
    background: url(../../assets/images/mobile.png) no-repeat center;
    background-size: contain;
    height: 416px;
    margin-bottom: 2.2rem;

    @media screen and (max-width: $screen-sm) {
      background-position: top;
      margin-top: 1rem;
    }
  }

  h1 {
    text-transform: uppercase;
  }

  p {
    line-height: 1.7rem;
  }

  .scout-input-box {
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.24);
    box-sizing: border-box;
    border-radius: 4px;

    width: clamp(320px, 40ch, 100%);

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;

    padding: 0.25rem;

    input {
      box-sizing: border-box;
      border-radius: 0;
      color: $white;
      padding: 0 0.5rem;
      font-size: 16px;
      height: auto;
      // width: 100%;
      flex-grow: 1;
      border: 0 none transparent;
      outline: 0 none transparent;
      background: transparent;
    }

    input::placeholder {
      color: $white-half;
    }

    button {
      padding: 12px 1rem;
      white-space: nowrap;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
