.modal-container {
  position: fixed;
  background-color: rgba(47, 53, 59, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  .modalCover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .modal {
    position: fixed;
    text-align: center;
    z-index: 6;
    height: fit-content;
    min-height: 10rem;
    width: 100%;
    bottom: 0;
    background-color: $white;
    border-radius: 1rem 1rem 0 0;
    box-sizing: border-box;

  }
  &.modalIsHigherThanScreen {
    align-items: initial;
    .modal {
      position: relative;
      margin: 4rem 0 0 0;
    }
  }
  @media (min-width: $screen-lg) {
    .modal{
        text-align: start;
        width: 34rem;
        border-radius: 1rem;
        bottom: auto;
    }
    &.modalIsHigherThanScreen .modal{
        margin: 4rem 0;
    }
  }
}
