button {
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;

  &.button {
    &:disabled, &[disabled] {
      background: #87A5BB !important;
      box-shadow: none !important;
      cursor: default;
    }
  }

  &.button--large {
    padding: 16px 3rem;
  }

  &.primary {
    background: linear-gradient(180deg, #5E9EFF 0%, rgba(27, 113, 241, 0.75) 100%);
    box-shadow: 4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5), inset 1px 2px 2px rgba(191, 217, 255, 0.5);
    color: $white;

    &:hover {
      background: linear-gradient(180deg, #558DE1 0%, rgba(22, 92, 199, 0.75) 100%);
      box-shadow: 4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5), inset 1px 2px 2px rgba(191, 217, 255, 0.5);
    }

    &:active {
      background: linear-gradient(180deg, rgba(27, 113, 241, 0.75) 0%, #5E9EFF 100%);
      box-shadow: inset 2px 3px 4px rgba(53, 123, 230, 0.5), inset 2px 1px 2px rgba(191, 217, 255, 0.5);
    }
  }

  &.whitelist {
    background: linear-gradient(180deg, #FF7B5E 0%, rgba(241, 27, 27, 0.75) 100%);
    box-shadow: 4px 24px 24px rgba(196, 140, 140, 0.25), inset -2px -3px 4px rgba(230, 53, 53, 0.5), inset 1px 2px 2px rgba(255, 191, 191, 0.5);
    padding: 22px 80px !important;
    font-weight: 600;
    color: $white;

    &:hover {
      background: linear-gradient(180deg, rgba(241, 27, 27, 0.75) 0%, #FF7B5E 100%);
      box-shadow: 4px 24px 24px rgba(196, 140, 140, 0.25), inset -2px -3px 4px rgba(230, 53, 53, 0.5), inset 1px 2px 2px rgba(255, 191, 191, 0.5);
    }

    &:active {
      background: linear-gradient(180deg, rgba(236, 69, 69, 0.842) 0%, rgba(255, 124, 94, 0.747) 100%);
      box-shadow: 4px 24px 24px rgba(196, 140, 140, 0.25), inset -2px -3px 4px rgba(230, 53, 53, 0.5), inset 1px 2px 2px rgba(255, 191, 191, 0.5);
    }
  }

  &.secondary {
    background: linear-gradient(180deg, #E9F1F9 0%, rgba(245, 250, 255, 0.75) 100%);
    box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1), inset 1px 2px 2px #FFFFFF;
    color: $main;

    &:hover {
      background: linear-gradient(180deg, #DDEBFA 0%, rgba(245, 250, 255, 0.75) 100%);
      box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1), inset 1px 2px 2px #FFFFFF;
    }

    &:focus {
      background: linear-gradient(180deg, rgba(245, 250, 255, 0.75) 0%, #E9F1F9 100%);
      box-shadow: inset 1px 2px 3px rgba(155, 173, 188, 0.1);
    }
  }
}
