.roadmap-section {
  background: rgba(135, 165, 187, 0.025);
  border: 1px solid rgba(135, 165, 187, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  margin-right: 16px;

  p {
    padding: 0;
    margin: 0;
  }

  .header {
    padding: 8px;
    background: $white-gradient;
    box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset 1px 2px 2px #ffffff;
    border-radius: 8px;
  }

  .period {
    color: $main;
  }

  .event {
    display: flex;
    padding-top: 16px;

    p {
      padding-left: 10px;
    }
  }

  .bullet {
    background: radial-gradient(
      75.4% 75.4% at 22.62% 11.9%,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(216, 236, 255, 0.788722) 49.46%,
      rgba(175, 217, 255, 0.675) 100%,
      rgba(175, 217, 255, 0.675) 100%,
      rgba(175, 217, 255, 0.675) 100%
    );
    box-shadow: 4px 16px 24px rgba(70, 122, 201, 0.15), inset 1px 3px 4px rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(8px);
    min-width: 14px;
    width: 14px;
    min-height: 14px;
    height: 14px;
    display: flex;
    border-radius: 50%;
  }

  .emphasize {
    p {
      // text-transform: uppercase;
      color: $main;
    }

    .bullet {
      background: url(../../assets/icons/finish.svg) no-repeat center;
    }
  }

  .done {
    p {
      // text-transform: uppercase;
      // color: $success;
    }

    .bullet {
      background: none;
      box-shadow: none;
      backdrop-filter: blur(0);
      border-radius: 0;
    }
    .bullet::after {
      content: "✔";
      // color: $success;
      min-width: 14px;
      width: 14px;
      min-height: 14px;
      height: 14px;
      border-radius: 0;
    }
  }
}
