.rfund-page {
  background: radial-gradient(27.27% 162.47% at 27.27% 21.95%, #2072ec 0%, #2178fb 100%);

  .header {
    background-color: transparent;
  }

  .rfund-hero {
    background: url(../../assets/images/rfund-hero.webp) no-repeat top;
    background-size: cover;
    height: clamp(636.8px, max-content, 100vh);
    // height: 796px;
    margin-bottom: 2.2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .hero-text {
      text-align: center;

      h1,
      h2,
      p {
        font-family: 'Vinnytsia Sans Bold';
        font-weight: bold;
        background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.75) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      h1,
      h2 {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1;
      }

      h1 {
        font-family: 'Bebas Neue';
        margin-top: 2rem;
        font-size: clamp(4rem, 10vw, 8rem);
      }

      @media (max-width: $screen-md) {
        letter-spacing: 0;
      }

      h2 {
        font-family: 'Vinnytsia Sans Bold';
        font-size: clamp(1.75rem, 5vw, 2.5rem);
        text-transform: uppercase;
      }

      p {
        font-family: 'Vinnytsia Sans Bold';
        max-width: 32ch;
        margin: 0 auto 1rem auto;
      }

      .hero-button {
        display: inline-block;
        background: linear-gradient(180deg, #e9f1f9 0%, rgba(245, 250, 255, 0.75) 100%);
        box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1),
          inset 1px 2px 2px #ffffff;
        text-align: center;
        border: none;
        border-radius: 4px;
        padding: 0.8rem 3rem;
        cursor: pointer;
        margin-bottom: 3rem;

        color: #2178fb;
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1;
      }
    }

    .hero-bottom {
      background: linear-gradient(162.23deg, rgba(239, 245, 255, 0.2304) 0.03%, rgba(239, 245, 255, 0.072) 83.37%);
      box-shadow: 4px 16px 12px rgba(103, 145, 208, 0.075), inset 1px 2px 2px rgba(255, 255, 255, 0.48);
      backdrop-filter: blur(8px);
      border-radius: 40px 40px 0px 0px;
      text-align: center;
      width: clamp(300px, 1440px, 90vw);
      // padding: 0 4rem;
      margin: 0 auto;

      h1 {
        font-family: 'Vinnytsia Sans Bold';
        font-size: clamp(2rem, 6vw, 3rem);
        text-transform: uppercase;
        font-weight: bold;
        background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.25) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 1.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1440px;
  margin: 0 auto;

  .about {
    position: relative;
    top: -4.5rem;
    background: linear-gradient(180deg, #e9f1f9 0%, rgba(245, 250, 255, 0.75) 100%);
    box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset 1px 2px 2px #ffffff;
    backdrop-filter: blur(40px);
    border-radius: 8px;
    padding: 2rem;

    display: grid;
    grid-template-columns: repeat(2, minmax(360px, 50%));
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'title image'
      'text image';
    align-items: center;

    h2 {
      grid-area: title;
      display: block;
      text-transform: uppercase;
    }

    p {
      grid-area: text;
      display: block;
    }

    .about-image {
      grid-area: image;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        object-fit: contain;
        aspect-ratio: 1.5 / 1;
      }
    }
  }

  @media (max-width: $screen-md) {
    .about {
      grid-template-columns: auto;
      grid-template-rows: repeat(3, auto);
      grid-template-areas:
        'title'
        'image'
        'text';
    }

    h2,
    p {
      text-align: center;
    }
  }

  .rfund-size {
    display: grid;
    grid-template-columns: 450px 750px;

    .size-text {
      padding: 2rem;

      h3 {
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: $screen-xl) {
    .rfund-size {
      grid-template-columns: auto !important;
      grid-template-rows: repeat(2);
      align-items: center;
      justify-content: center;

      .size-text {
        text-align: center;

        h3 {
          text-transform: uppercase;
        }
      }

      & > div:nth-child(2) {
        justify-self: center;
      }

      & > div:nth-child(2) > img {
        max-width: 90vw;
        height: auto;
      }
    }
  }

  .offering-title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2rem;
    text-transform: uppercase;
    max-width: 28ch;
  }

  .offering-grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(200px, 1fr));
    gap: 0.5rem;
    max-width: 100%;
    padding: 0 1rem;

    .offering-box {
      background: linear-gradient(180deg, #e9f1f9 0%, rgba(245, 250, 255, 0.75) 100%);
      box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset 1px 2px 2px #ffffff;
      border-radius: 8px;
      padding: 1rem;

      p {
        font-size: 16px;
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 0;
        text-align: center;
      }

      .offering-icon {
        min-width: 80px;
        max-width: 80px;
        min-height: 80px;
        max-height: 80px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        background: #f6f9fd;
        box-shadow: 4px 16px 12px rgba(103, 145, 208, 0.075), inset 1px 2px 2px #ffffff;
      }
    }
  }

  @media (max-width: $screen-xl) {
    .offering-grid {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas:
        'a a b b c c'
        '. d d e e .';

      .offering-box {
        max-width: 300px;
      }
      .offering-box:nth-child(1) {
        grid-area: a;
      }
      .offering-box:nth-child(2) {
        grid-area: b;
      }
      .offering-box:nth-child(3) {
        grid-area: c;
      }
      .offering-box:nth-child(4) {
        grid-area: d;
      }
      .offering-box:nth-child(5) {
        grid-area: e;
      }
    }
  }

  @media (max-width: $screen-md) {
    .offering-grid {
      grid-template-columns: 1fr;
      grid-template-areas: 'a' 'b' 'c' 'd' 'e';
      // grid-template-rows: repeat(5, 1fr);
      // grid-template-areas: unset;

      .offering-box {
        max-width: 95vw;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .offering-icon {
          margin: 0;
          margin-right: 1ch;
        }
      }
    }
  }

  .offering2 {
    .offering2-title {
      max-width: 36ch;
    }

    .offering2-grid {
      display: grid;
      grid-template-columns: repeat(3, 216px);
      justify-content: center;
      gap: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;

      .offering2-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .offering2-icon {
          background: radial-gradient(
            75.4% 75.4% at 22.62% 11.9%,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(216, 236, 255, 0.788722) 49.46%,
            rgba(175, 217, 255, 0.675) 100%,
            rgba(175, 217, 255, 0.675) 100%,
            rgba(175, 217, 255, 0.675) 100%
          );
          box-shadow: 4px 16px 24px rgba(70, 122, 201, 0.15), inset 1px 3px 4px rgba(255, 255, 255, 0.75);
          backdrop-filter: blur(8px);
          border-radius: 50%;
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;
        }

        p {
          font-size: 16px;
          line-height: 1.5;
          text-align: center;
          color: $grey-for-white;
        }
      }
    }

    @media (max-width: $screen-md) {
      .offering2-grid {
        grid-template-columns: auto;
        grid-template-rows: repeat(3, auto);
        max-width: 95vw;
        gap: 0;

        .offering2-box {
          flex-direction: row;
          align-items: center;
          justify-self: center;
          width: 100%;

          .offering2-icon {
            margin-right: 1ch;
          }

          p {
            text-align: left;
          }
        }
      }
    }
  }
  .apply {
    background: linear-gradient(180deg, #e9f1f9 0%, rgba(245, 250, 255, 0.75) 100%);
    box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset 1px 2px 2px #ffffff;
    border-radius: 8px;
    flex-grow: 1;
    padding: 2rem;
    box-sizing: border-box;
    max-width: 1440px;
    // margin: 0 auto;
    margin-bottom: 4rem;

    .apply-grid {
      display: grid;
      grid-template-columns: repeat(3, minmax(300px, 1fr));
      grid-template-rows: minmax(320px, 1fr);
      gap: 1rem;

      @media (max-width: $screen-lg) {
        grid-template-columns: minmax(320px, 1fr);
        grid-template-rows: repeat(3, minmax(320px, 1fr));
      }

      .apply-grid-box {
        background: linear-gradient(180deg, #e9f1f9 0%, rgba(245, 250, 255, 0.75) 100%);

        /* 2 */
        box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset 1px 2px 2px #ffffff;
        border-radius: 8px;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        picture {
          display: flex;
          align-items: flex-end;
          margin: 0 auto;
          height: 200px;
        }

        h5 {
          text-transform: uppercase;
          color: $main;
          margin: 0;
          padding: 1rem;
          border-top: 1px solid rgba(216, 236, 255, 0.788722);
          text-align: center;
          box-shadow: 0 -10px 40px 8px white;
          clip-path: polygon(0px 100%, 100% 100%, 100% -125%, 0% -125%);
        }

        .apply-grid-box-step {
          background: radial-gradient(
            75.4% 75.4% at 22.62% 11.9%,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(216, 236, 255, 0.788722) 49.46%,
            rgba(175, 217, 255, 0.675) 100%,
            rgba(175, 217, 255, 0.675) 100%,
            rgba(175, 217, 255, 0.675) 100%
          );
          box-shadow: 4px 16px 24px rgba(70, 122, 201, 0.15), inset 1px 3px 4px rgba(255, 255, 255, 0.75);
          backdrop-filter: blur(8px);
          border-radius: 50%;

          position: absolute;
          left: 50%;
          right: 50%;
          top: -1.25rem;
          transform: translate(-50%, 0);
          min-width: 2.5rem;
          max-width: 2.5rem;
          min-height: 2.5rem;
          max-height: 2.5rem;

          display: flex;
          justify-content: center;
          align-items: center;
          color: $main;
          font-size: 0.75rem;
          font-weight: bold;
        }
      }
    }

    .apply-link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1rem 3rem;
      margin-top: 2rem;
      color: $white;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-family: 'Circe bold';

      /* btn */
      background: linear-gradient(180deg, #5e9eff 0%, rgba(27, 113, 241, 0.75) 100%);
      /* btn */
      box-shadow: 4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5),
        inset 1px 2px 2px rgba(191, 217, 255, 0.5);
      border-radius: 4px;
    }
  }

  .faq {
    box-sizing: border-box;
    width: clamp(300px, 90vw, 1200px);
    // max-width: 1200px;
  }

  .faq-box {
    background: linear-gradient(180deg, #e9f1f9 0%, rgba(245, 250, 255, 0.75) 100%);
    box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset 1px 2px 2px #ffffff;
    border-radius: 8px;
    margin-bottom: 1rem;

    details {
      padding: 0;
    }

    summary {
      position: relative;
      cursor: pointer;
      list-style: none;
      text-transform: uppercase;
      padding-right: 2rem;
      line-height: 2;
      outline: 0;
      border: 0;
      overflow: hidden;

      strong {
        display: block;
        padding: 1rem;
      }
    }

    summary::-webkit-details-marker {
      display: none;
    }

    // hr {
    //   border-color: $separator;
    // }

    p {
      color: $grey-for-white;
      padding: 1rem;
    }

    .collapse-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: #f6f9fd;
      box-shadow: 4px 16px 12px rgba(103, 145, 208, 0.075), inset 1px 2px 2px #ffffff;
      border-radius: 50%;
      min-width: 2rem;
      max-width: 2rem;
      min-height: 2rem;
      max-height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      text-align: center;
      z-index: 2;
    }

    .collapse-button::before {
      min-width: 0.75rem;
      max-width: 0.75rem;
      min-height: 0.75rem;
      max-height: 0.75rem;
      display: inline-block;
      // content: '\2023';
      content: url(../../assets/icons/caret_down.svg);
      transform-origin: 50% 50%;
      transform-origin: center;
      transition: transform 250ms ease-in-out;
      z-index: 3;
    }

    details[open] > summary {
      color: $main;
    }

    details > summary .collapse-button::before svg > path {
      fill: $main;
    }

    details[open] > summary .collapse-button::before {
      transform: rotate(180deg);
    }

    details[open] > summary .collapse-button::before svg > path {
      fill: $grey-for-white;
    }
  }

  .scout {
    background: linear-gradient(180deg, #5e9eff 0%, rgba(27, 113, 241, 0.75) 100%);
    box-shadow: 4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5),
      inset 1px 2px 2px rgba(191, 217, 255, 0.5);
    border-radius: 4px;
    width: calc(100vw - 6rem);
    max-width: 1440px;

    // margin: 4rem 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;

    .scout-box {
      max-width: 1200px;
      flex-grow: 1;
      // display: flex;
      // justify-content: space-between;

      h4,
      p {
        color: $white;
      }

      h4 {
        text-transform: uppercase;
      }

      p {
        max-width: 360px;
        font-size: 16px;
      }

      @media (max-width: $screen-lg) {
        p {
          max-width: auto;
          text-align: left;
        }
      }
    }

    .scout-button {
      display: block;
      background: linear-gradient(180deg, #e9f1f9 0%, rgba(245, 250, 255, 0.75) 100%);
      box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1),
        inset 1px 2px 2px #ffffff;
      text-align: center;
      border: none;
      border-radius: 4px;
      padding: 0.8rem 3rem;
      cursor: pointer;

      color: #2178fb;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1;
    }

  }
}
