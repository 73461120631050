.dapp-container {
  .features {
    margin-top: 3rem;
  }

  .title {
    p {
      line-height: 26px;

      @media screen and (min-width:$screen-sm) {
        line-height: 28px;
      }

      @media screen and (min-width:$screen-lg) {
        line-height: 32px;
      }
    }
  }

  @media screen and (max-width:$screen-sm) {
    .feature {
      padding-right: 0.5rem;
    }
  }
}
