.partner {
  justify-content: flex-end;
  align-items: center;
  background: $white-gradient;
  box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset 1px 2px 2px #FFFFFF;
  border-radius: 8px;
  margin: 0 0.4rem 0.8rem;

  &.empty {
    background: none;
    box-shadow: none;

    @media screen and (max-width: $screen-md) {
      display: none;
    }
  }

  width: 150px;
  height: 140px;

  @media screen and (min-width: $screen-md) {
    margin: 0 0.6rem 0.6rem;
    width: 200px;
    height: 150px;
  }

  @media screen and (min-width: $screen-lg) {
    width: 226px;
  }

  hr {
    width: 40px;
  }
}
