.icon-rounded {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  background: linear-gradient(180deg, #E9F1F9 0%, rgba(245, 250, 255, 0.75) 100%);
  box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1), inset 1px 2px 2px #FFFFFF;

  &.flat {
    background: #F6F9FD;
    box-shadow: 4px 16px 12px rgba(103, 145, 208, 0.075), inset 1px 2px 2px #FFFFFF;
  }

  &.large {
    width: 80px;
    height: 80px;
  }
}

