.input-field {
  display: inline-block;
  width: 100%;
  position: relative;
  background: rgba(211, 223, 234, 0.1);
  border: 1px solid rgba(211, 223, 234, 0.5);
  border-radius: 0.25rem;
  box-sizing: border-box;

  .error-message {
    display: none;
    position: absolute;
    margin-top: -10px;
    background: #FF4C4C;
    box-shadow: 0px 4px 40px rgba(255, 58, 58, 0.5), 0px 8px 16px rgba(0, 0, 0, 0.05);
    border-radius: 0.25rem;
    &.active {
      display: block;
    }

    p {
      color: $white;
      font-size: 14px;
      padding: 0.2rem 1.2rem;
      margin: 0;
    }

    z-index:99999999;
    box-sizing:border-box;


    &::after {
      content: "";
      transform: translate(-50%,50%) rotate(45deg);
      position: absolute;
      bottom: 100%;
      left: 30%;
      width: 8px;
      height: 8px;
      background: #FF4C4C;
    }

  }
}

input {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  height: 56px;
  color: $dark;
  padding: 16px;
  font-size: 16px;
  width: 100%;

  &::placeholder {
    font-size: 16px;
    color: $grey-for-white;
  }

  &:focus {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(33, 120, 251, 0.25);
    box-sizing: border-box;
  }

  &[disabled] {
    color: $grey-for-white;
  }
}
