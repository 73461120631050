footer {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: $screen-sm) {
    flex-direction: column;
    align-items: flex-start;

    .links {
      align-self: center;
      align-items: center;
      padding-top: 1rem;

      a {
        margin-top: 0.5rem;
      }
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;

    a {
      padding-right: 2rem;
    }
  }

  .icon-rounded {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    z-index: 3;

    @media (min-width: $screen-sm) {
      bottom: 2rem;
    }

    &.show {
      display: block;
    }
  }
}

p.copyright {
  font-size: 12px;
}
