@import "./fonts";

html, body {
  font-family: 'Circe';
  font-size: 16px;
  line-height: 24px;

  @media screen and (min-width:$screen-lg) {
    font-size: 20px;
    line-height: 28px;
  }

  h1 {
    font-size: 32px;
    line-height: 1.25;

    @media screen and (min-width:$screen-sm) {
      font-size: 40px;
    }

    @media screen and (min-width:$screen-lg) {
      font-size: 48px;
    }
  }

  h2 {
    font-size: 24px;
    line-height: 1.3;

    @media screen and (min-width:$screen-sm) {
      font-size: 32px;
    }

    @media screen and (min-width:$screen-lg) {
      font-size: 40px;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 1.5;

    @media screen and (min-width:$screen-sm) {
      font-size: 24px;
    }
  }

  p.xl {
    font-size: 16px;
    line-height: 1.5;

    @media screen and (min-width:$screen-sm) {
      font-size: 18px;
    }

    @media screen and (min-width:$screen-lg) {
      font-size: 32px;
    }
  }

  p.large, a.large {
    @media screen and (min-width:$screen-sm) {
      font-size: 18px;
      line-height: 26px;
    }

    @media screen and (min-width:$screen-lg) {
      font-size: 28px;
      line-height: 40px;
    }
  }

  p.small, a.small {
    font-size: 14px;
    line-height: 1.4;

    @media screen and (min-width:$screen-sm) {
      font-size: 16px;
    }
  }

  p.extra-small, a.extra-small {
    font-size: 14px;
    line-height: 20px;
  }

  h1, h2, h3, h4, h5, p, b, em, a {
    color: $dark;
  }

  a {
    text-decoration: none;
    color: $main;
  }

  p.light, a.light {
    color: $secondary;
  }

  .bold {
    font-family: 'Circe Bold';
  }

  .uppercase {
    text-transform: uppercase;
  }
}
