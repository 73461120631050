@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-weight: normal;
  src: local('Circe'), url('../assets/fonts/circe/CRC55.woff') format('woff');
}

@font-face {
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Circe Bold'), url('../assets/fonts/circe/CRC65.woff') format('woff');
}

@font-face {
  font-family: 'Vinnytsia Sans Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Vinnytsia Sans Bold'),
    url('../assets/fonts/VinnytsiaSansBold/fonts/VinnytsiaSansBold.eot') format('embedded-opentype'),
    url('../assets/fonts/VinnytsiaSansBold/fonts/VinnytsiaSansBold.woff2') format('woff2'),
    url('../assets/fonts/VinnytsiaSansBold/fonts/VinnytsiaSansBold.woff') format('woff'),
    url('../assets/fonts/VinnytsiaSansBold/fonts/VinnytsiaSansBold.ttf') format('truetype'),
    url('../assets/fonts/VinnytsiaSansBold/fonts/VinnytsiaSansBold.svg') format('svg');
}

@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: bold;
  src: local('Bebas Neue'), url('../assets/fonts/BebasNeue/BebasNeue-Regular.eot') format('embedded-opentype'),
    url('../assets/fonts/BebasNeue/BebasNeue-Regular.woff2') format('woff2'),
    url('../assets/fonts/BebasNeue/BebasNeue-Regular.woff') format('woff'),
    url('../assets/fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype'),
    url('../assets/fonts/BebasNeue/BebasNeue-Regular.otf') format('truetype');
}

//** ICONS **//

@font-face {
  font-family: 'revuto';
  src: url('../assets/fonts/icons/revuto.eot?96357139');
  src: url('../assets/fonts/icons/revuto.eot?96357139#iefix') format('embedded-opentype'),
    url('../assets/fonts/icons/revuto.woff2?96357139') format('woff2'),
    url('../assets/fonts/icons/revuto.woff?96357139') format('woff'),
    url('../assets/fonts/icons/revuto.ttf?96357139') format('truetype'),
    url('../assets/fonts/icons/revuto.svg?96357139#revuto') format('svg');
  font-weight: normal;
  font-style: normal;
}

.revuto-icon {
  font-family: 'revuto';
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: flex;
  justify-content: center;
}

.icon-facebook:before {
  content: '\e800';
} /* '' */
.icon-twitter:before {
  content: '\e801';
} /* '' */
.icon-linkedin:before {
  content: '\e802';
} /* '' */
.icon-income:before {
  content: '\e803';
} /* '' */
.icon-coins:before {
  content: '\e804';
} /* '' */
.icon-telegram:before {
  content: '\e805';
} /* '' */
.icon-rewards:before {
  content: '\e806';
} /* '' */
.icon-card:before {
  content: '\e807';
} /* '' */
.icon-phone:before {
  content: '\e808';
} /* '' */
.icon-wallet:before {
  content: '\e809';
} /* '' */
.icon-menu:before {
  content: '\e80a';
} /* '' */
.icon-arrow-right:before {
  content: '\e80b';
} /* '' */
.icon-cancel:before {
  content: '\e80c';
} /* '' */
.icon-discord:before {
  content: '\e80d';
} /* '' */
.icon-instagram:before {
  content: '\e80e';
} /* '' */
.icon-medium:before {
  content: '\e80f';
} /* '' */
.icon-youtube-play:before {
  content: '\f16a';
} /* '' */
.icon-tiktok:before {
  content: '\e811';
} /* '' */
.icon-reddit-alien:before {
  content: '\f281';
} /* '' */
