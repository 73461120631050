.social-container {
  border-radius: 8px;
  background: $main;

  p, h1, h2, h3, h4, h5, a, b, .light {
    color: $white;
  }

  .icon-rounded {
    margin-right: 1rem;
    background: linear-gradient(90deg, #166CED 0%, #1E74F7 100%);
    box-shadow: 0px 16px 24px rgba(24, 111, 242, 0.5), inset 2px 4px 8px #3384FF;

    &:hover {
      a {
        color: $grey-for-blue;
      }
    }

    &:focus {
      a {
        color: #2564C3;
      }
    }
  }

  .icons {
    margin: 0.5rem 0 0.5rem 0;
  }

  @media screen and (max-width: $screen-sm) {
    // Mobile specific view
    .icon-rounded {
      margin: 0 1rem 1rem;
    }

    .icons {
      margin: 24px 0;
    }

    .button {
      margin-top: 16px;
      width: 100%;
    }
  }

  @media screen and (min-width: $screen-sm) {
    input {
      padding-right: 130px;
    }
    .button {
      position: relative;
      margin-left: -130px;
    }
  }
}
