.cta-container {
  background: linear-gradient(180deg, #e9f1f9 0%, #f5faff 100%);
  box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset 1px 2px 2px #ffffff;
  border-radius: 40px;
  align-items: center;
  margin: -2.6rem 1.6rem 1.6rem;
  padding: 2rem;
  z-index: 10;
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  .v-hidden {
    visibility: hidden;
  }

  .paper {
    display: none;
  }

  .vertical-separator {
    display: none;
  }

  .buy-ordinals {
    color: #2178fb;
    display: inline-block;
    font-family: 'Circe Bold';
    margin-bottom: 2rem;
  }

  h4 {
    margin: 0;
  }

  p {
    color: $grey-for-white;
  }

  .widget-app h4 {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  .app-badges {
    width: 135px;
    aspect-ratio: 3.35 / 1;
  }

  .mobile-links {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;

    @media screen and (max-width: $screen-sm) {
      align-items: center;
      justify-content: center;
      flex: 1;
      margin-bottom: 12px;
    }
  }

  @media screen and (max-width: $screen-md) {
    display: block;

    button {
      margin-top: 1rem;
      flex: 1;
    }
  }

  @media screen and (max-width: $screen-sm) {
    img {
      width: 120px;
      height: 40px;
      aspect-ratio: 3/1;
    }

    .app-badges {
      width: 120px;
      aspect-ratio: 3.35 / 1;
    }
  }

  @media screen and (min-width: $screen-md) {
    .vertical-separator {
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: $grey-for-white;
      opacity: 0.25;
    }

    .widget-app h4 {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $screen-xl) {
    gap: 2.5rem;

    .paper {
      display: block;
      position: absolute;
      width: 132px;
      aspect-ratio: 1 / 1.5;
      left: calc(50% - 56px);
      top: -106px;
      opacity: 0.9;
      z-index: 2;
    }
  }
}

#crypto-widget-CoinList {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
