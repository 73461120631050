.btc-page {
  background: radial-gradient(27.27% 162.47% at 27.27% 21.95%, #2072ec 0%, #2178fb 100%);

  .header {
    background-color: transparent;
  }

  .btc-hero {
    background: radial-gradient(27.27% 162.47% at 27.27% 21.95%, #2072ec 0%, #2178fb 100%);
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;

    .btc-hero-header {
      display: flex;
      justify-content: center;
      padding-left: 1ch;
      padding-right: 1ch;
      width: 100%;

      h1 {
        color: white;
        font-family: 'Circe Bold';
        font-size: 40px;
        max-width: 22ch;
        text-align: center;
      }
    }

    .btc-hero-subheading {
      color: white;
      display: block;
      font-size: 20px;
      padding-left: 1ch;
      padding-right: 1ch;
      text-align: center;
    }

    .btc-hero-inputs {
      display: flex;
      flex-direction: column;
      max-width: 1200px;
      margin: 0 auto;

      @media screen and (max-width: 1200px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .btc-hero-inputs-grid {
        column-gap: 24px;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-auto-flow: column;
        grid-template-rows: repeat(2, minmax(0, 1fr));
        padding-bottom: 1rem;
        padding-top: 1rem;
        row-gap: 12px;
        margin: 0 auto;

        @media screen and (max-width: 600px) {
          grid-template-columns: 1fr;
          grid-auto-flow: unset;
          grid-template-rows: auto;
        }

        input,
        select {
          background-color: white;
          border: 1px solid #0060df;
          border-radius: 4px;
          box-sizing: border-box;
          color: #2f353b;
          font-size: 16px;
          height: auto;
          line-height: 1;
          max-width: 360px;
          outline: 0;
          padding: 0.25rem 0.75rem;
          width: 100%;
        }

        input::placeholder {
          color: #003eaa;
        }

        input:disabled {
          opacity: 0.8;
        }

        input.invalid {
          background-color: hsl(0, 76%, 77%);
          color: white;
        }

        input:last-child {
          background-image: url('../../assets/icons/dollar.svg');
          background-position: 0.25rem center;
          background-repeat: no-repeat;
          background-size: 1rem 0.75rem;
          padding-left: 1rem;
        }
      }

      button {
        all: unset;
        background-color: white;
        border-radius: 0.25rem;
        box-shadow: 0px 0px 2px 4px rgba(255, 255, 255, 0.25);
        color: #2f353b;
        cursor: pointer;
        font-weight: 500;
        line-height: 1;
        margin-left: auto;
        padding: 0.5rem 1.5rem;
        text-transform: uppercase;
      }
    }

    .mobile-links {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin: 0 auto;

      @media screen and (max-width: $screen-sm) {
        align-items: center;
        justify-content: center;
        flex: 1;
        margin-bottom: 12px;
      }
    }

    .mobile-links > * {
      display: flex;
      height: 40px;
      justify-content: center;
      object-fit: contain;
      width: auto;
    }

    .mobile-links img {
      object-fit: contain;
    }

    .mobile-links img:nth-child(odd) {
      width: 135px;
    }

    .mobile-links img:nth-child(2) {
      width: 120px;
    }

    .btc-hero-docs {
      display: flex;
      justify-content: space-between;
      padding: 0 4rem;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        padding: 0 2rem;
      }

      p {
        color: white;
        max-width: 32ch;
        font-weight: 500;
      }

      p:first-child {
        text-align: start;

        @media screen and (max-width: 600px) {
          text-align: center;
        }
      }

      p:last-child {
        text-align: end;

        @media screen and (max-width: 600px) {
          text-align: center;
        }
      }

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }
}

.btc-why {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding-bottom: 2rem;
  padding-top: 4rem;

  @media screen and (max-width: 1200px) {
    gap: 4rem;
    grid-template-columns: minmax(0, 1fr);
    grid-auto-rows: auto;
  }

  @media screen and (min-width: 2400px) {
    grid-template-columns: 40% 60%;
  }

  .btc-ordinals {
    display: flex;
    flex-direction: column;
    padding-left: 4rem;

    @media screen and (max-width: 1200px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @media screen and (max-width: 600px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    h2 {
      color: #f65018;
      font-family: 'Circe Bold';
      font-size: 36px;
      margin-bottom: 0;
      margin-top: 0;
    }

    h3 {
      color: #2178fb;
      font-family: 'Circe Bold';
      font-size: 24px;
      margin-top: 0;
      max-width: 32ch;
    }

    .btc-ordinal-list,
    .btc-grandordinal-list {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      @media screen and (max-width: 440px) {
        gap: 1rem;
      }
    }

    .btc-ordinal-list {
      align-self: start;

      @media screen and (max-width: 600px) {
        align-self: center;
      }
    }

    .btc-grandordinal-list {
      align-self: end;
      margin-top: 2rem;

      @media screen and (max-width: 600px) {
        align-self: center;
      }
    }

    .btc-ordinal h4 {
      font-size: 16px;
      margin-bottom: 0.5rem;

      @media screen and (max-width: 600px) {
        max-width: 120px;
      }
    }

    .btc-ordinal img {
      display: block;
      height: 200px;
      width: 200px;

      @media screen and (min-width: 2000px) {
        height: 260px;
        width: 260px;
      }

      @media screen and (max-width: 1400px) {
        height: 160px;
        width: 160px;
      }

      @media screen and (max-width: 600px) {
        height: 120px;
        width: 120px;
      }

      @media screen and (max-width: 440px) {
        height: 90px;
        width: 90px;
      }
    }
  }

  .btc-lambo {
    display: grid;
    grid-auto-rows: auto;

    img:first-child {
      align-self: start;
      height: 100px;
      justify-self: end;
      padding-right: 4rem;

      @media screen and (max-width: 600px) {
        height: auto;
        justify-self: center;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 2rem;
        max-width: calc(100vw - 2rem);
      }
    }

    img:last-child {
      align-self: end;
      justify-self: end;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
