.community-container {
  padding-top: 4rem;
  padding-bottom: 2.5rem;

  .row {
    flex-direction: column-reverse;
  }

  p {
    line-height: 1.5rem;
    text-align: justify;
  }

  @media screen and (min-width:$screen-md) {
    .row {
      flex-direction: row;
    }
  }

  img {
    max-width: 100%;
  }
}
