.header {
  background-color: $main;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  .nav-options {
    display: flex;
    align-items: center;
    list-style-type: none;
    li {
      padding-right: 16px;
      font-family: 'Circe Bold';
      font-size: 16px;
      cursor: pointer;
    }

    a {
      color: $grey-for-blue !important;

      &:hover {
        color: $white !important;
      }

      &:focus {
        color: $grey-for-blue !important;
      }
    }
  }

  .mobile-menu,
  .icon-arrow-right {
    display: none;
  }

  /* Mobile View */
  @media (max-width: $screen-md) {
    .mobile-menu {
      display: flex;

      .icon-menu {
        // color: $secondary;
        color: $white;
      }
    }

    .icon-cancel {
      // color: $grey;
      color: $white;
    }

    .nav-links {
      position: relative;
      z-index: 100;
    }

    .nav-options {
      margin: 0;

      display: flex;
      // height: 480px;
      // height: calc(100vh - 120px);
      // height: calc(100dvh - 80px);
      position: fixed;
      top: 92.3px;
      left: -100%;
      // bottom: 0;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      grid-gap: 0;
      overflow-y: auto;
      overscroll-behavior-y: contain;

      a {
        color: $dark !important;

        &:hover {
          color: $main !important;
        }

        &:focus {
          color: #2564c3 !important;
        }
      }
    }

    .nav-options.active {
      background: $background;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      align-items: center;
      padding: 1rem;

      li {
        width: 90%;
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 6px;
        padding-left: 1rem;

        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;
        }

        .icon-arrow-right {
          display: flex;
          color: $grey;
          font-size: 20px;
          padding-right: 5px;
        }
      }
    }

    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10vw;
      padding: 10px 0;
    }
  }
}
