.change-password-modal {
  padding: 1.2rem;
  background-color: $white !important;
  width: 100% !important;

  @media screen and (min-width: $screen-lg) {
    width: 448px !important;
  }
  h2 {
    font-size: 1.2rem;
    margin-bottom: .2rem;
    margin-top: 0;
  }
  h3, .form-label {
    font-size: .8rem !important;
    color: $secondary;
    font-weight: normal;
    margin-top: .2rem;
  }
  i {
    color: $secondary;
    font-size: 1rem;
  }
  a {
    margin-left: 0.4rem;
    color: $main !important;
    font-size: 0.7rem;
    &:hover {
      color: inherit !important;
    }
  }
  .sep {
    height: 1px;
    flex: 1;
    margin-left: 1.2rem;
    background-color: $separator;
  }
  .change-password-form {
    margin-top: 1.2rem;
    .change-password-error {
      font-size: 0.6rem;
      color: $red;
      line-height: 0.6rem;
      padding-left: 0.7rem
    }
    .change-password-success {
      font-size: 0.6rem;
      color: $main;
      line-height: 0.6rem;
      padding-left: 0.7rem
    }
    .change-password-input {
      margin-bottom: 0.4rem;
      &::-webkit-input-placeholder {
        color: $grey-for-white;
      }
    }
  }
}

.change-password-btn {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  width: 100%;
  color: $white;
  background: $blue-gradient;
}

.dashboard-link {
  margin-top: 1.2rem;
  a {
    color: $main;
    cursor: pointer;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
}