.custom-tooltip {
  max-width: 330px;
}

.member {
  background: rgba(135, 165, 187, 0.025);
  border: 1px solid rgba(135, 165, 187, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem 0 0.5rem;
  margin-bottom: 1rem;
  position: relative;

  width: 150px;
  margin-right: 0.6rem;

  @media screen and (min-width: $screen-sm) {
    width: 190px;
  }

  @media screen and (min-width: $screen-md) {
    width: 224px;
    margin-right: 0.65rem;
  }

  @media screen and (min-width: $screen-lg) {
    width: 215px;
    height: 246px;
  }

  img {
    background: #F6F9FD;
    box-shadow: 4px 16px 12px rgba(103, 145, 208, 0.075), inset 1px 2px 2px #FFFFFF;
    border-radius: 50%;

    width: 72px;
    height: 72px;
  }

  .position {
    background: rgba(135, 165, 187, 0.05);
    border: 1px solid rgba(135, 165, 187, 0.25);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
    text-align: center;

    @media screen and (min-width: $screen-lg) {
      position: absolute;
      bottom: 0;
    }
  }

  &:hover {
    cursor: pointer;

    .position {
      color: $main;
    }
  }
}
