@import "~normalize.css";
@import '~react-multi-carousel/lib/styles.css';
@import "./variables";
@import "./flexboxgrid";

@import "./typography";
@import "./common";
@import "./pagination";



body {
  background-color: $background;
  overflow-x: hidden;
}

@import "../components";
@import "../pages";
@import "../containers";

