.feature {
  display: flex;
  padding-bottom: 2rem;
  flex-direction: column;

  button {
    cursor: auto;

    &:focus {
      outline: none;
    }
  }

  .revuto-icon {
    font-size: 1.6rem;
    color: $white;
  }

  .caption {
    flex: 1;
    justify-content: space-evenly;
    margin-top: 1rem;

    p {
      margin: 0;
    }
  }

  @media screen and (min-width:$screen-md) {
    flex-direction: row;

    .caption {
      margin-top: 0;

      p {
        padding: 0 1.2rem;
      }
    }
  }
}
