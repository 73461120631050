.nft-claim-modal {
  max-width: 448px;
  padding: 32px;
  text-align: center !important;
  h3 {
    margin: 0;
  }
  p {
    margin: 16px 0 !important;
    font-size: 16px !important;
  }
  .nft-img-container {
    width: 384px;
    height: 284px;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .nft-id{
    margin: 16px 0;
  }
  button {
    width: 100%;
    height: 72px;
  }
  .responseMessage{
    color: $white;
    border-radius: 4px;
    padding: 8px;
    margin-top: 12px;
    &.success{
      background: $blue-gradient;
    }
    &.failure{
      background-color: rgba($color: #fb2121, $alpha: 0.8);
    }
  }
}
