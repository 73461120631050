$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

$mq-small: "(min-width: #{$screen-sm})";
$mq-medium: "(min-width: #{$screen-md})";
$mq-large: "(min-width: #{$screen-lg})";
$mq-xlarge: "(min-width: #{$screen-xl})";

$white: #FFFFFF;
$white-half: #FFFFFF80;
$dropdown-background: #F9FBFCFF;
$background: #F5F8FB;
$main: #2178FB;
$secondary: #87A5BB;
$dark: #2F353B;
$grey: rgba(135, 165, 187, 0.25);
$grey-for-white: #87A5BB;
$grey-for-blue: #9DC2F9;
$white-gradient: linear-gradient(180deg, #E9F1F9 0%, rgba(245, 250, 255, 0.75) 100%);
$blue-gradient: linear-gradient(180deg, #5E9EFF 0%, rgba(27, 113, 241, 0.75) 100%);
$separator: #D8E6F0;
$red: #fb2121;
$success: #77c66e;