.pagination {

  li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    height: 30px;
    margin-right: 8px;
    list-style: none;
    background-color: $white;
    border: 1px solid $secondary;
    border-radius: 2px;
    outline: 0;
    cursor: pointer;
    user-select: none;

    a {
      font-size: 0.9rem;
      display: block;
      padding: 0 6px;
      color: $secondary;
      transition: none;
    }

    &.active {
      font-weight: 500;
      border-color: $main;

      a {
        color: $main;
      }
    }

    &.break-me {
      background: transparent;
      border: none;
      cursor: default;

      a {
        color: #666;
      }
    }
  }
}
