.token-container {
  .token-image {
    background: url(../../assets/images/token.png) no-repeat center;
    background-size: contain;
    height: 100%;
    min-height: 262px;
  }

  .points {
    margin-top: 2rem;
    justify-content: space-between;

    .point {
      margin-right: 3rem;
    }
  }

  .ellipse {
    background: radial-gradient(75.4% 75.4% at 22.62% 11.9%, rgba(255, 255, 255, 0.9) 0%, rgba(216, 236, 255, 0.788722) 49.46%, rgba(175, 217, 255, 0.675) 100%, rgba(175, 217, 255, 0.675) 100%, rgba(175, 217, 255, 0.675) 100%);
    box-shadow: 4px 16px 24px rgba(70, 122, 201, 0.15), inset 1px 3px 4px rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(8px);
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}
