.countdown {
  display: flex;
  width: 100%;
  margin: 0 -8px !important;
  flex-wrap: wrap;

  &--item {
    flex: 1;
    margin: 0 4px !important;
    background: rgba(207, 220, 232, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.75);
    box-sizing: border-box;
    box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1),
      inset 1px 2px 2px #ffffff;
    backdrop-filter: blur(10px);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 80px;
    min-width: 70px;

    @media screen and (max-width: $screen-md) {
      max-width: 80px;
    }

      @media screen and (min-width: $screen-md) {
      min-height: 136px;
    }

    h5 {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      text-transform: uppercase;
      color: $main;
      display: block;
      margin: 0;

      @media screen and (min-width: $screen-md) {
        font-size: 48px;
        line-height: 56px;
      }
    }

    p {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: $secondary;
      margin: 0 !important;

      @media screen and (min-width: $screen-md) {
        font-size: 16px;
      }
    }
  }
}
