.sale-status {
  display: flex;
  flex: 1;
  background: rgba(207, 220, 232, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.75);
  box-sizing: border-box;
  box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1),
    inset 1px 2px 2px #ffffff;
  backdrop-filter: blur(10px);
  width: 100%;
  height: 56px;
  border-radius: 32px;

  @media screen and (min-width: $screen-md) {
    height: 88px;
    border-radius: 20px;
  }

  &--indicator {
    background: linear-gradient(180deg, #5e9eff 0%, rgba(27, 113, 241, 0.75) 100%);
    box-shadow: 4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5),
      inset 1px 2px 2px rgba(191, 217, 255, 0.5);
    border-radius: 16px 4px 4px 16px;
    position: relative;
    margin: 8px !important;
  }

  &--left {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);

    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    text-transform: uppercase;
    color: white;

    @media screen and (min-width: $screen-md) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &--right {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);

    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    text-transform: uppercase;
    color: #87a5bb;

    @media screen and (min-width: $screen-md) {
      font-size: 24px;
      line-height: 32px;
    }
  }
}
