.nft-box-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  p {
    padding-top: 0.2rem;
  }
}

.nft-box {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;
  width: 183px;
  height: 160px;
  margin: 0 0.6rem;
  background: linear-gradient(180deg, #e9f1f9 0%, #f5faff 100%);
  border-radius: 16px;
  box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.3), inset 1px 2px 2px #ffffff;
  padding: 16px;

  @media screen and (max-width: $screen-md) {
    width: 143px;
    height: 128px;
  }

  @media screen and (max-width: $screen-sm) {
    width: 130px;
    height: 115px;
    padding: 8px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    image-rendering: -webkit-optimize-contrast;
  }
}
