.tier-card {
  background: rgba(207, 220, 232, 0.25);
  border: 0.0625rem solid rgba(255, 255, 255, 0.75);
  box-sizing: border-box;
  box-shadow: 0.25rem 2rem 2.5rem rgba(140, 168, 196, 0.1),
    inset -0.0625rem -0.125rem 0.1875rem rgba(190, 198, 205, 0.1), inset 0.0625rem 0.125rem 0.125rem #ffffff;
  backdrop-filter: blur(0.625rem);
  border-radius: 1.25rem;
  padding: 1.2rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $screen-sm) {
    padding: 1.3rem;
  }

  @media screen and (min-width: $screen-lg) {
    padding: 1.5rem;
  }

  &--number {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f9fd;
    box-shadow: 0.25rem 1rem 0.75rem rgba(103, 145, 208, 0.075), inset 1px 2px 2px #ffffff;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-bottom: 2rem !important;

    @media screen and (min-width: $screen-lg) {
      width: 5rem;
      height: 5rem;
    }
  }

  &--title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.25rem;

    @media screen and (min-width: $screen-lg) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  &--subtitle {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $secondary;

    @media screen and (min-width: $screen-lg) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  &--list {
    list-style-type: none;
    padding: 0;
    margin: 1.5rem 0 !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: $screen-lg) {
      display: grid;
      grid-template-columns: repeat(3, 4fr);
    }

    h5 {
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.25rem;
      text-transform: uppercase;
    }
    p {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $secondary;
    }
  }

  button {
    border-radius: 4px;
    height: 56px;
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    cursor: default;

    &.active {
      background: linear-gradient(180deg, #5E9EFF 0%, rgba(27, 113, 241, 0.75) 100%) !important;
      box-shadow: 4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5), inset 1px 2px 2px rgba(191, 217, 255, 0.5);
      color: $white !important;

      &:focus, &:hover {
        // Disable button changes on focus and hover, not clicable
        background: linear-gradient(180deg, #5E9EFF 0%, rgba(27, 113, 241, 0.75) 100%) !important;
        box-shadow: 4px 24px 24px rgba(140, 168, 196, 0.25), inset -2px -3px 4px rgba(53, 123, 230, 0.5), inset 1px 2px 2px rgba(191, 217, 255, 0.5);
        color: $white !important;
      }
    }

    @media screen and (max-width: $screen-lg) {
      max-width: 100%;
    }
  }
}
