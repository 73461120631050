.invest {
  &--header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;

    p {
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-transform: uppercase;
      color: $secondary;

      @media screen and (min-width: $screen-md) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    h2 {
      font-weight: bold;
      text-transform: uppercase;
      color: $main;
    }
  }

  &--tiers {
    display: grid;
    row-gap: 1rem;

    @media screen and (min-width: $screen-md) {
      grid-template-columns: repeat(3, 4fr);
      column-gap: 1rem;
    }
  }

  &--code {
    display: flex;
    justify-content: center;

    margin-top: 1.5rem !important;
    //margin-bottom: 5.75rem !important;

    &-input {
      margin-bottom: 1rem !important;
      align-items: center;

      &-container {
        position: relative;

        input {
          padding-right: 2rem;
        }

        span {
          color: $grey;
          position: absolute;
          right: 0.5rem;
          top: 50%;
          transform: translate(0, -50%);
          z-index: 10;
        }

        .error-message {
          display: none;
          position: absolute;
          margin-top: -10px;
          background: #FF4C4C;
          box-shadow: 0 4px 40px rgba(255, 58, 58, 0.5), 0 8px 16px rgba(0, 0, 0, 0.05);
          border-radius: 0.25rem;
          &.active {
            display: block;
          }

          p {
            color: $white;
            font-size: 14px;
            padding: 0.2rem 1.2rem;
            margin: 0;
          }

          z-index:99999999;
          box-sizing:border-box;

          &::after {
            content: "";
            transform: translate(-50%,50%) rotate(45deg);
            position: absolute;
            bottom: 100%;
            left: 10%;
            width: 8px;
            height: 8px;
            background: #FF4C4C;
          }
        }
      }


      h3 {
        font-size: 0.875rem;
        line-height: 1.25rem;

        @media screen and (min-width: $screen-md) {
          font-size: 1.75rem;
          line-height: 2.5rem;
        }

        @media screen and (min-width: $screen-md) {
          font-size: 1.75rem;
          line-height: 2.5rem;
        }
      }
    }

    &--deposit-container {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $screen-lg) {
        flex-direction: row;
      }
    }

    &-inputs {
      @media screen and (min-width: $screen-lg) {
        display: flex;
        flex-direction: column;
        margin-right: 1rem !important;
        margin-top: 0.75rem !important;
      }

      p.note {
        text-align: center;
        margin: 0 auto;
        max-width: 60%;
      }
    }

    &-input {
      @media screen and (min-width: $screen-lg) {
        display: grid;
        grid-template-columns: 9.375rem 1fr;
        column-gap: 1rem;
      }
    }

    &-qr {
      @media screen and (min-width: $screen-md) {
        display: flex;
        align-items: center;
      }

      canvas {
        margin: 2rem auto 0;
        display: block;

        @media screen and (min-width: $screen-md) {
          margin-left: 2rem;
          margin-top: 0;
        }
      }
    }

    &-countdown {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .countdown {
        flex-wrap: nowrap;
      }

      h3 {
        width: auto;
      }

      @media screen and (min-width: $screen-md) {
        .countdown {
          width: 38rem;
        }
      }

      @media screen and (min-width: $screen-xl) {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
      }
    }

    &-deposit {
      text-align: center;

      a, u {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      .rules-caption {
        justify-content: center;

        a {
          margin-left: 0.2rem;
          margin-right: 0;

          &:hover {
            cursor: pointer;
          }
        }
      }

      p {
        margin-top: 1rem;
      }

      h5 {
        color: #87a5bb;
        margin-top: -1.25rem;
      }

      h3 {
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        color: #87a5bb;
        height: 3.5rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (min-width: $screen-sm) {
          font-size: 1rem;
          line-height: 1.25rem;
        }

        @media screen and (min-width: $screen-lg) {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }

      .copy-input {
        position: relative;
        max-width: 100%;

        input {
          padding-right: 2.5rem;
        }

        button {
          border: 0;
          background-color: none;
          padding: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1rem;
        }
      }
    }
  }

  &--table {
    margin-top: 1rem !important;

    background: rgba(207, 220, 232, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.75);
    box-sizing: border-box;
    box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1),
    inset 1px 2px 2px #ffffff;
    backdrop-filter: blur(10px);
    border-radius: 1.25rem;
    padding: 1rem;
    margin-bottom: 1rem;

    .table-tx {
      display: flex;
      justify-content: space-between;
      width: 100%;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        margin-left: 0.5rem;
      }
    }

    .cell-success, .MuiDataGrid-booleanCell[data-value="true"] {
      color: $main !important;
    }
    .cell-failed, .MuiDataGrid-booleanCell[data-value="false"] {
      color: $red !important;
    }

    &-content {
      height: 320px !important;
      width: 100%;
      border: none !important;
    }

    @media screen and (min-width: $screen-sm) {
      padding: 1.5rem;
    }

    h3 {
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-size: 0.875rem;

      @media screen and (min-width: $screen-md) {
        font-size: 1rem;
      }

      @media screen and (min-width: $screen-xl) {
        font-size: 1.5rem;
      }
    }
  }

  &--charts {
    margin-top: 1rem !important;

    @media screen and (min-width: $screen-lg) {
      row-gap: 1rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(9.375rem, 1fr));
      column-gap: 1rem;
    }

    .area-chart-wrapper {
      display: flex;
      flex-direction: column;

      .invest--charts-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    > div {
      background: rgba(207, 220, 232, 0.25);
      border: 1px solid rgba(255, 255, 255, 0.75);
      box-sizing: border-box;
      box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1),
        inset 1px 2px 2px #ffffff;
      backdrop-filter: blur(10px);
      border-radius: 1.25rem;
      padding: 1rem;
      margin-bottom: 1rem;

      @media screen and (min-width: $screen-sm) {
        padding: 1.5rem;
      }

      h3 {
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-size: 0.875rem;

        @media screen and (min-width: $screen-md) {
          font-size: 1rem;
        }

        @media screen and (min-width: $screen-xl) {
          font-size: 1.5rem;
        }
      }
    }

    &-wrapper {
      @media screen and (min-width: $screen-sm) {
        display: grid;
        grid-template-columns: 1fr 104px;
        column-gap: 1.25rem;
        margin-top: 1.25rem !important;
        grid-template-columns: repeat(auto-fit, minmax(9.375rem, 1fr));
        column-gap: 3.75rem;
      }

      &.area-chart {
        grid-template-columns: 1fr 15.625rem;
        column-gap: 1.25rem;
      }

      &.flex-chart {
        @media screen and (max-width: $screen-sm) {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }

    &-chart {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;

      img {
        max-height: 350px;
        margin-bottom: 2rem;
      }
    }

    &-info {
      @media screen and (max-width: $screen-sm) {
        margin-top: 1rem !important;
        display: grid;
        grid-template-columns: repeat(2, 6fr);
      }

      &.flex-info {
        @media screen and (max-width: $screen-sm) {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .sale-status-container {
    &:first-child {
      @media screen and (min-width: $screen-lg) {
        margin-right: 0.5rem;
      }
    }
  }

  .total-tokens p {
    margin-top: 0rem;
  }
}
