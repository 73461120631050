.cta-form{
  .cta-wrapper{
    padding: 2rem;
    h2{margin: 0;}
    .description{
      color: $grey-for-white;
      margin: 1rem 0;
      &.response{
        text-align: center;
      }
    }
    .responseMessage{
      margin-bottom: 1rem;
      text-align: center;
      font-size: 1.75rem
    }
    form{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem 0.5rem;
      grid-template-areas:
        "firstName lastName"
        "email email"
        "captcha captcha"
        "button button"
        ;
    }
    .firstName { grid-area: firstName; }
    .lastName { grid-area: lastName; }
    .email { grid-area: email; }
    .captcha { grid-area: captcha;}
    button{
      width: 100%;
      grid-area: button;
      height: 54px;
    }
    p{
      width: auto;
    }
  }
}