.nft-page {
  background: $white;
  text-align: center;
  .header {
    background: $white;
    li a {
      color: $grey-for-white !important;
      &:hover {
        color: $grey-for-blue !important;
      }
    }
  }
  h1{
    margin: 1rem 0 0 0;
  }
  h2{
    text-transform: uppercase;
    font-size: 1.7rem;
    margin: 0;
    margin-bottom: 32px;
  }
  .buyersNFT{

    img{
      width: 80%;
      max-width: 400px;
      max-height: 400px;
      object-fit: contain;
      padding: 32px;
      box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.2), inset 1px 2px 2px #FFFFFF;
      background: linear-gradient(180deg, #E9F1F9 0%, #F5FAFF 100%);
      border-radius: 16px;
    }
  }
  .deadline {
    color: $white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 40px;
    background: linear-gradient(75deg, rgba(255, 88, 88, 0.85) 10.57%, rgba(255, 83, 83, 0.6375) 90.82%);
  }
  h1 {
    text-transform: uppercase;
    font-weight: 700;
  }
  p {
    max-width: 76ch;
    margin: auto;
    font-size: 18px;
    line-height: 30px;

    @media screen and (max-width: $screen-sm) {
      padding: 0 0.1rem;
    }
  }
  .image-row {
    padding: 24px 0;
    overflow: hidden;
    height: 350px;
    .fixed-img {
      flex-shrink: 0;
      width: 280px;
      height: 240px;
      margin: 0 32px;
      background: linear-gradient(180deg, #e9f1f9 0%, #f5faff 100%);
      border-radius: 16px;
      box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.3), inset 1px 2px 2px #ffffff;
      padding: 16px;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
  .try-your-luck {
    margin: 16px auto;
    width: 348px;
    padding: 8px;
    border: 1px solid rgba(135, 165, 187, 0.25);
    border-radius: 8px;
    button {
      width: 100%;
      height: 72px;
    }
    .nft-counter {
      display: flex;
      justify-content: space-around;
      color: $grey-for-white;
      font-size: 14px;
      margin: 8px 0;
      span {
        &:first-child {
          font-size: 28px;
          line-height: 40px;
        }
      }
    }
    .logOut{
      height: 32px;
      width: 70%;
    }
  }
}
