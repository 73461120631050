.dashboard {
  &--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--status {
    width: 100%;

    h3 {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  &--charts {
    margin-top: 1rem !important;

    > div:first-child {
      .chart-container {
        margin-right: 1rem;
      }
    }

    .chart-container {
      height: 100%;
      background: rgba(207, 220, 232, 0.25);
      border: 1px solid rgba(255, 255, 255, 0.75);
      box-sizing: border-box;
      box-shadow: 4px 32px 40px rgba(140, 168, 196, 0.1), inset -1px -2px 3px rgba(190, 198, 205, 0.1),
        inset 1px 2px 2px #ffffff;
      backdrop-filter: blur(10px);
      border-radius: 1.25rem;
      padding: 1rem;

      @media screen and (min-width: $screen-sm) {
        padding: 1.3rem;
      }

      h3 {
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-size: 0.875rem;

        @media screen and (min-width: $screen-md) {
          font-size: 1rem;
        }

        @media screen and (min-width: $screen-xl) {
          font-size: 1.5rem;
        }
      }
    }

    &-wrapper {
      @media screen and (min-width: $screen-sm) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(9.375rem, 1fr));
        column-gap: 3.75rem;
      }
    }

    &-chart {
      height: 100%;
      width: 100%;
    }

    .notice {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $secondary;
      margin-top: 1.5rem;
    }
  }
}
