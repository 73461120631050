.token-sale {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $main;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 1.5rem 2rem;

    .header-logo {
      height: 2rem;
      filter: invert(100%);
    }

    .link-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 1rem;



      a {
        text-align: left;

        color: $grey-for-blue !important;

        &:hover {
          color: $white !important;
        }

        &.active {
          color: $white !important;
        }
      }
    }

    .header-button {
      font-family: Circe;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      outline: none;

      margin-left: auto;
    }

    @media #{$mq-small} {
      flex-wrap: nowrap;

      .link-container {
        width: auto;
        margin-top: 0;
        order: 2;
      }

      .header-button {
        order: 3;
        margin-left: 0;
      }

      a {
        margin-right: 1.5rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media #{$mq-medium} {
      a {
        margin-right: 4rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  main {
    * {
      margin: 0;
      box-sizing: border-box;
    }

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $background;

    margin-left: 1rem;
    margin-right: 1rem;

    border-radius: 1rem;
    padding: 0.5rem;

    @media screen and (min-width:$screen-sm) {
      margin-left: 2rem;
      margin-right: 2rem;
      padding: 1rem;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 2rem;

    font-size: 14px;
    line-height: 1.4;

    @media screen and (min-width:$screen-sm) {
      font-size: 16px;
    }

    .active {
      color: $white !important;
    }

    a {
      color: $grey-for-blue;
      margin: 0;

      &:hover {
        color: $white;
      }

      &:focus {
        color: $grey-for-blue;
      }
    }

    @media #{$mq-small} {
      flex-direction: row;
      flex-wrap: wrap;

      a {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media #{$mq-medium} {
      a {
        width: auto;
      }
    }

    @media #{$mq-xlarge} {
      a {
        margin-right: 5rem;
        width: auto;

        &:last-child {
          margin-right: 0;
          // for learn more!
          // margin-left: auto;
        }
      }
    }
  }
}

.rules-container {
  padding: 0.8rem;
  background-color: $background !important;
  width: 95% !important;
  max-height: 80%;
  top: 10%;
  overflow-y: scroll;
  border-radius: 1rem !important;
  text-align: left !important;

  @media screen and (min-width: $screen-md) {
    width: 80% !important;
    padding: 1.2rem;
  }

  @media screen and (min-width: $screen-lg) {
    padding: 2rem;
    top: 10%;
    max-height: 85%;
  }

  h2 {
    font-size: 1.2rem;
    margin-bottom: .2rem;
    margin-top: 0.5rem;
  }

  h3 {
    font-size: .8rem !important;
    color: $secondary;
    font-weight: normal;
    margin-top: .2rem;
  }

  .list-container {
    display: flex;
    flex-direction: column;

    .list-item {
      display: flex;
      flex-wrap: nowrap;
      text-align: justify;

      .bullet {
        margin-right: 0.5rem !important;
        color: $dark;
        opacity: 0.75;
      }
    }
  }
}
